<template>
  <div class='page-box'>
    <base-screen class="custom-screen">
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-row>
        <a-form-model-item label="审核状态" prop="state">
          <a-select v-model="queryRef.state" placeholder='请选择' style="width: 200px">
            <a-select-option v-for='item in splitState' :key='item.value' :value="item.value">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
          <a-form-model-item class="screen-btns">
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button @click="handleReset">重置</a-button>
          </a-form-model-item>
        </a-row>
      </a-form-model>
    </base-screen>
<!--    <div class="btn-box">-->
<!--      <a-button type="primary" @click="exportFn" :loading="exporting"> 导出 </a-button>-->
<!--    </div>-->
    <div class='btn-box'>
      <a-button type='primary' @click='getSplitAddData'>创建拆分</a-button>
    </div>
    <base-table :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.userSharerId" :loading="loadingRef" @change="handleTableChange">
      <template #state="record">
        <span>{{splitState.find(x=>x.value === record).name}}</span>
      </template>
      <template #operation="_,record">
        <div class='table-operations '>
          <a-button type='link' @click='edit(record)' v-if='record.state == 0'>编辑</a-button>
          <a-button type='link' @click='commitCheck(record)' v-if='record.state == 0'>提交审核</a-button>
          <a-button type='link' @click='deleteLog(record)' v-if='record.state == 0'>删除</a-button>
          <a-button type='link' @click='pass(record)' v-if='record.state == 1'>通过</a-button>
          <a-button type='link' @click='refused(record)' v-if='record.state == 1'>拒绝</a-button>
          <a-button type='link' @click='run(record)' v-if='record.state == 2'>执行拆分</a-button>
        </div>
      </template>
    </base-table>
    <a-modal v-model='visibleAdd' :width="800" :title='isAdd?"创建拆分":"编辑拆分"' :confirm-loading='confirmLoading' @ok='handleSubmit' @cancel='handelCancel'>
      <a-form-model ref='formAddRef' :model="form" :rules="rules" :wrapper-col="{span:16}" :label-col="{span:8}">
        <a-row>
          <a-col :span='12'>
            <a-form-model-item label='当前已分配股数' prop='allotAs'>
              <a-input-number :precision="2" v-model="form.allotAs" placeholder='请输入' style="width: 150px" :disabled="true"></a-input-number>
            </a-form-model-item>
          </a-col>
          <a-col :span='12'>
            <a-form-model-item label='等待分配总额/元' prop='allotWaitMoney'>
              <a-input-number :precision="2" v-model="form.allotWaitMoney" placeholder='请输入' style="width: 150px" :disabled="true"></a-input-number>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span='8'>
            <a-form-model-item label='理论单价' prop='allotAs'>
              <a-input-number :precision="2" v-model="form.priceReal" placeholder='请输入' style="width: 150px" :disabled="true"></a-input-number>
            </a-form-model-item>
          </a-col>
          <a-col :span='16'>
            <div style="color: red;margin-top: 10px">理论单价=(已分配的总额+待分配的总额)/已分配的总额</div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span='12'>
            <a-form-model-item label='系统显示单价' prop='priceShow'>
              <a-input-number :precision="2" v-model="form.priceShow" placeholder='请输入' style="width: 150px"
                              @blur="handleInputBlur" @input="handleInputBlur"
              ></a-input-number>
            </a-form-model-item>
          </a-col>
          <a-col :span='12'>
            <a-form-model-item label='拆分配送倍数' prop='allotBs'>
              <a-input-number :precision="2" v-model="form.allotBs" placeholder='请输入' style="width: 150px"
                              @blur="handleInputBlur" @input="handleInputBlur"
              ></a-input-number>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span='12'>
            <a-form-model-item label='拆分后单价' prop='priceAfter'>
              <a-input-number :precision="2" v-model="form.priceAfter" placeholder='请输入' style="width: 150px" :disabled="true"></a-input-number>
            </a-form-model-item>
          </a-col>
          <a-col :span='12'>
            <a-form-model-item label='拆分后系统股数' prop='asAfter'>
              <a-input-number :precision="2" v-model="form.asAfter" placeholder='请输入' style="width: 150px" :disabled="true"></a-input-number>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span='24'>
            <a-form-model-item :label-col="{span:4}" :wrapper-col="{span:20}"  label='通知内容' prop='content'>
              <a-textarea v-model="form.content" placeholder="请输入" :rows="4" />
              <div style="color: red">审核通过后，系统会根据通知内容自动发送通知给移动端</div>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { shop } from '@/api'
import { splitState } from '../../utils/constants'
import { checkFloat } from '@/utils/validator'
const columns = [
  {
    title: '拆分时已分配股数',
    dataIndex: 'allotAs',
  },
  {
    title: '拆分时等待分配金额',
    dataIndex: 'allotWaitMoney',
  },
  {
    title: '拆分时理论单价',
    dataIndex: 'priceReal',
  },
  {
    title: '拆分时显示单价',
    dataIndex: 'priceShow',
  },
  {
    title: '配送倍数',
    dataIndex: 'allotBs',
  },
  {
    title: '拆分后单价',
    dataIndex: 'priceAfter',
  },
  {
    title: '拆分后系统股数',
    dataIndex: 'asAfter',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]

export function downloadFile (fetcher, defaultFileName = '下载_' + Date.now()) {
  return fetcher().then((res) => {
    const { headers, data: blob } = res
    const m = /filename=("|')?(.+)\1($|\s|;)/.exec(headers['content-disposition'])
    const serverSuggestName = m && m[2] ? decodeURIComponent(m[2]) : defaultFileName

    // IE & 旧版本Edge
    if ('msSaveOrOpenBlob' in navigator) {
      navigator.msSaveOrOpenBlob(blob, serverSuggestName)
    } else {
      const el = document.createElement('a')
      const url = URL.createObjectURL(blob)
      el.setAttribute('href', url)
      el.setAttribute('download', serverSuggestName)
      el.style.display = 'none'
      document.body.append(el)
      el.click()
      setTimeout(() => {
        URL.revokeObjectURL(url)
        el.remove()
      })
    }
  })
}

export function useExport (fetcher, defaultFileName) {
  const exporting = ref(false)
  async function exportFn () {
    exporting.value = true
    await downloadFile(fetcher, defaultFileName)
    exporting.value = false
  }
  return {
    exporting,
    exportFn
  }
}
export default {
  setup (props, { root }) {
    const formRef = ref(null)
    const formAddRef = ref(null)
    const rules = ref({
      priceShow: [
        { required: true, message: '请输入', trigger: 'change' },
        { validator: checkFloat, trigger: 'change' },
      ],
      allotBs: [
        { required: true, message: '请输入', trigger: 'change' },
        { validator: checkFloat, trigger: 'change' },
      ],
      content: [
        { required: true, message: '请输入', trigger: 'change' },
      ],
    })

    const queryRef = ref({
      state: undefined,
    })
    const isAdd = ref(false)
    const confirmLoading = ref(false)
    const visibleAdd = ref(false)
    const visibleCheck = ref(false)
    const dataRef = ref([])
    const pageRtv = ref({
      current: 1,
      size: 10,
      total: 0,
    })
    const form = ref({
      allotAs: undefined,
      allotWaitMoney: undefined,
      priceReal: undefined,
      priceShow: undefined,
      allotBs: undefined,
      priceAfter: undefined,
      asAfter: undefined,
      content: undefined,
      state: 0,
    })
    const loadingRef = ref(false)
    onMounted(() => {
      Promise.all([getTableData()])
    })
    async function getTableData () {
      loadingRef.value = true
      dataRef.value = []
      const { data, page } = await shop.getSplitPage({ ...queryRef.value, ...pageRtv.value })
      loadingRef.value = false

      dataRef.value = data || []
      pageRtv.value.total = page.total
    }

    function handleSearch () {
      pageRtv.value.current = 1
      getTableData()
    }

    async function handleReset () {
      formRef.value.resetFields()
      await root.$nextTick()
      handleSearch()
    }

    function handleTableChange ({ current }) {
      pageRtv.value.current = current
      getTableData()
    }

    async function getSplitAddData () {
      const { data, msg, code } = await shop.getSplitAddData()
      if (code === '00000') {
        form.value = data
        isAdd.value = true
        visibleAdd.value = true
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    async function handleSubmit () {
      formAddRef.value.validate((v) => {
        if (v) {
          root.$confirm({
            title: '操作提示',
            content: '确认提交吗？',
            onOk: async () => {
              submit()
            }
          })
        }
      })
    }
    async function submit () {
      confirmLoading.value = true
      const { msg, code } = await shop.saveSplit(form.value)
      confirmLoading.value = false
      if (code === '00000') {
        root.$message.success('提交成功')
        handelCancel()
        getTableData()
      } else {
        root.$message.error(msg || '提交失败')
      }
    }
    async function handelCancel () {
      formAddRef.value.resetFields()
      visibleAdd.value = false
    }
    function handleInputBlur () {
      form.value.priceAfter = (form.value.priceShow / form.value.allotBs).toFixed(2)
      form.value.asAfter = (form.value.allotAs * form.value.allotBs).toFixed(2)
    }
    async function edit (record) {
      form.value = record
      visibleAdd.value = true
      isAdd.value = false
    }
    async function commitCheck (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认提交审核吗？',
        onOk: async () => {
          const { code, msg } = await shop.commitCheckSplit({
            logId: record.logId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    async function deleteLog (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认刪除吗？',
        onOk: async () => {
          const { code, msg } = await shop.deleteSplit({
            logId: record.logId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    async function pass (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认审核通过吗？',
        onOk: async () => {
          const { code, msg } = await shop.passSplit({
            logId: record.logId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    async function refused (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认拒绝审核吗？',
        onOk: async () => {
          const { code, msg } = await shop.refusedSplit({
            logId: record.logId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    async function run (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认执行拆分吗？',
        onOk: async () => {
          const { code, msg } = await shop.runSplit({
            logId: record.logId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }

    const { exportFn, exporting } = useExport(() => shop.exportWithdrawInfo(queryRef.value))

    return {
      queryRef,
      formRef,
      formAddRef,
      handleTableChange,
      handleReset,
      handleSearch,
      isAdd,
      confirmLoading,
      handleSubmit,
      handelCancel,
      visibleAdd,
      visibleCheck,
      columns,
      rules,
      form,
      handleInputBlur,
      splitState,
      loadingRef,
      dataRef,
      pageRtv,
      getSplitAddData,
      edit,
      commitCheck,
      deleteLog,
      pass,
      refused,
      run,
      exportFn,
      exporting
    }
  },
}
</script>

<style lang="less" scoped>
@label-width: 84px;
.custom-screen {
  ::v-deep .solt-div {
    padding: 10px 0 0;
  }
  ::v-deep .ant-form-item-label {
    width: @label-width;
  }
}
.screen-btns {
  margin-left: @label-width;
}
.btn-date-shortcut {
  margin: 4px 12px 0 0;
  &:last-child {
    margin-right: 0;
  }
}
.record-list {
  margin-bottom: 32px;
  .record-item {
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
